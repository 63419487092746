function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { bindActions } from './bind-actions';
import createStoreState from './create-state';
export const GLOBAL_SCOPE = '__global__';
export class StoreRegistry {
  constructor(defaultScope) {
    var _this = this;

    if (defaultScope === void 0) {
      defaultScope = GLOBAL_SCOPE;
    }

    _defineProperty(this, "stores", new Map());

    _defineProperty(this, "initStore", (Store, key) => {
      const {
        initialState,
        actions
      } = Store;
      const storeState = createStoreState(key, initialState);
      const boundActions = bindActions(actions, storeState);
      const store = {
        storeState,
        actions: boundActions
      };
      this.stores.set(key, store);
      return store;
    });

    _defineProperty(this, "getStore", function (Store, scopeId) {
      if (scopeId === void 0) {
        scopeId = _this.defaultScope;
      }

      const key = _this.generateKey(Store, scopeId);

      return _this.stores.get(key) || _this.initStore(Store, key);
    });

    _defineProperty(this, "deleteStore", function (Store, scopeId) {
      if (scopeId === void 0) {
        scopeId = _this.defaultScope;
      }

      const key = _this.generateKey(Store, scopeId);

      _this.stores.delete(key);
    });

    _defineProperty(this, "generateKey", (Store, scopeId) => `${Store.key.join('__')}@${scopeId}`);

    this.defaultScope = defaultScope;
  }

}
export const defaultRegistry = new StoreRegistry();