import defaults from '../defaults';

const namedMutator = (storeState, actionName) => function () {
  storeState.mutator.actionName = actionName;
  return storeState.mutator(...arguments);
};

export const bindAction = function bindAction(storeState, actionFn, actionKey, getContainerProps, boundActions) {
  if (getContainerProps === void 0) {
    getContainerProps = () => {};
  }

  if (boundActions === void 0) {
    boundActions = {};
  }

  // Setting mutator name so we can log action name for better debuggability
  const dispatch = function dispatch(thunkFn, actionName) {
    if (actionName === void 0) {
      actionName = `${actionKey}.dispatch`;
    }

    return thunkFn({
      setState: defaults.devtools ? namedMutator(storeState, actionName) : storeState.mutator,
      getState: storeState.getState,
      actions: boundActions,
      dispatch
    }, getContainerProps());
  };

  return function () {
    return dispatch(actionFn(...arguments), actionKey);
  };
};
export const bindActions = function bindActions(actions, storeState, getContainerProps, boundActions) {
  if (getContainerProps === void 0) {
    getContainerProps = () => ({});
  }

  if (boundActions === void 0) {
    boundActions = null;
  }

  return Object.keys(actions).reduce((acc, k) => {
    acc[k] = bindAction(storeState, actions[k], k, getContainerProps, boundActions || acc);
    return acc;
  }, {});
};