function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import { createHook } from './hook';

function hasAdditionalProps(props) {
  for (let i in props) return true;

  return false;
}

export function createSubscriber(Store, _temp) {
  let {
    selector,
    displayName = ''
  } = _temp === void 0 ? {} : _temp;
  const useStore = createHook(Store, {
    selector
  });

  const Subscriber = function Subscriber(_ref) {
    let {
      children
    } = _ref,
        rest = _objectWithoutPropertiesLoose(_ref, ["children"]);

    const api = useStore(hasAdditionalProps(rest) ? rest : undefined);
    return children(...api);
  };

  Subscriber.displayName = displayName || `Subscriber(${Store.key[0]})`;
  return Subscriber;
}